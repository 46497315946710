import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  triggerFetchComments,
  stopFetchComments,
} from "../slices/progressSlice.ts";
import { RootState } from "../store";

export const useCommentSections = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(triggerFetchComments(user.id as string));
    return () => {
      dispatch(stopFetchComments());
    };
  }, [user, dispatch]);
};
