import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

export enum ProgressSymbol {
    LBR = 'LBR',
    LRB = 'LRB'
};

interface SettingsState {
    progressSymbols: ProgressSymbol
}
  
const initialState: SettingsState = {
    progressSymbols: ProgressSymbol.LRB
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setProgressSymbolsSetting: (state, action: PayloadAction<SettingsState['progressSymbols']>) => {
            state.progressSymbols = action.payload;
        },
    } 
})

export const { setProgressSymbolsSetting} = settingsSlice.actions;
export default settingsSlice.reducer;

export const setProgressSymbols = createAction<{
    progressSymbol: ProgressSymbol;
}>('settings/progressSymbol')

export const triggerFetchSettings = createAction<string>('settings/fetchStart');
export const stopFetchSettings = createAction('settings/fetchStop');