import { createAction, createSlice } from "@reduxjs/toolkit";

interface EditModeState {
    enabled: boolean
}
  
const initialState: EditModeState = {
    enabled: false
};


export const editModeSlice = createSlice({
    name: 'editMode',
    initialState,
    reducers: {
        toggleEditMode: (state) => {
            state.enabled = !state.enabled;
        },
    } 
})

export const { toggleEditMode} = editModeSlice.actions;
export default editModeSlice.reducer;

export const updateWorkName = createAction<{
    newWorkName: string,
    oldWorkName: string,
    workRefPath: string,
    teacherUid: string,
  }>('editMode/updateWorkName');

  export const createNewWork = createAction<{
    workIndex: number,
    subject: string,
    teacherUid: string,
  }>('editMode/createNewWork')

  export const deleteWork = createAction<{
    workIndex: number,
    subject: string,
    teacherUid: string,
  }>('editMode/deleteWork')