// features/classroom/classroomSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurriculumYear, Status, StudentModel, SubjectWorkOrderModel, UpdateNotesModel, UpdateStatusModel, WorkModel } from '../types.ts';
import { addDayLog, updateClassWorkStudentProgress, updateClassWorkStudentProgressNotes } from '../oldApi.js';

interface ClassroomState {
  classroom: {
    _id: string;
    name: string;
    nameAbbreviated: string;
    students: StudentModel[];
    studentsShown: StudentModel[];
    student_order: string[];
    works: WorkModel[];
    worksShown: WorkModel[];
    work_orders_by_subject: SubjectWorkOrderModel[];
    subjects: string[];
    activeStudentNotes: string;
    activeSubject: string;
    activeCurriculumYear: CurriculumYear | null;
    school_id: string;
    teacher_id: string;
    activeStudent: StudentModel;
    teacher_name: string;
  };
}

const initialState: ClassroomState = {
  classroom: {
    _id: "LoadingID",
    name: "Loading",
    nameAbbreviated: "Loading",
    students: [{
      _id: "BlankStudentID",
      name: "BlankStudent",
      yearInCurriculum: "1st"
    }],
    studentsShown: [{
      _id: "BlankStudentID",
      name: "BlankStudent",
      yearInCurriculum: "1st"
    }],
    works: [{
      _id: "BlankWorkID",
      id: 1,
      name: "BlankWork",
      subject: "Language",
      progress: [{
        student_id: "BlankStudentID",
        student_name: "Blank Student",
        status: Status.Mastered,
        notes: "some notes",
      }]
    }],
    worksShown: [{
      _id: "BlankWorkID",
      id: 1,
      name: "BlankWork",
      subject: "Language",
      progress: [{
        student_id: "BlankStudentID",
        student_name: "Blank Student",
        status: Status.Mastered,
        notes: "some notes",
      }]
    }],
    subjects: [
      "Language",
      "Science",
      "Sensorial",
      "Math",
      "Practical Life",
      "Geography"
    ],
    work_orders_by_subject: [{subject_name: "Language", work_order: ["BlankWorkID"]}],
    student_order: ["BlankStudentID"],
    activeStudentNotes: "BlankStudentID",
    activeSubject: "Language",
    activeCurriculumYear: null,
    school_id: "school_id",
    teacher_id: "teacher_id",
    teacher_name: "Teacher",
    activeStudent: {
      _id: "BlankStudentID",
      name: "BlankStudent",
      yearInCurriculum: "1st"
    },
  },
};

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {
    setClassroom: (state, action: PayloadAction<ClassroomState['classroom']>) => {
      console.log("Setting classroom to", action.payload);
      state.classroom = {...state.classroom, ...action.payload};
      state.classroom.works.sort((a: WorkModel, b: WorkModel) => {
        const subjectAIndex = state.classroom.subjects.indexOf(a.subject);
        const subjectBIndex = state.classroom.subjects.indexOf(b.subject);

        if (subjectAIndex !== subjectBIndex) {
            return subjectAIndex - subjectBIndex; // Sort by subject index first
        }

        // If subjects are the same, sort by _id within the subject
        const workOrderAIndex = state.classroom.work_orders_by_subject.find(work_order => work_order.subject_name === a.subject)?.work_order.indexOf(a._id) ?? -1;
        const workOrderBIndex = state.classroom.work_orders_by_subject.find(work_order => work_order.subject_name === b.subject)?.work_order.indexOf(b._id) ?? -1;

        return workOrderAIndex - workOrderBIndex;
        /*return (state.classroom.subjects.indexOf(a.subject) < state.classroom.subjects.indexOf(b.subject) ||
          state.classroom.work_orders_by_subject.find(work_order => work_order.subject_name == a.subject)!.work_order.indexOf(a._id) < state.classroom.work_orders_by_subject.find(work_order => work_order.subject_name == a.subject)!.work_order.indexOf(b._id)) as unknown as number;*/
      })
      state.classroom.worksShown = state.classroom.works.filter(work => work.subject === action.payload.activeSubject);
      state.classroom.students = state.classroom.student_order.map(id => state.classroom.students.find(student => student._id === id)!);
      state.classroom.studentsShown = state.classroom.students;
      state.classroom.activeStudent =  state.classroom.students[0];
    },
    setWorks: (state, action: PayloadAction<ClassroomState['classroom']['works']>) => {
      console.log("Setting works");
      state.classroom.works = action.payload;
    },
    setWork: (state, action: PayloadAction<UpdateStatusModel>) => {
      const updateProgressInWork = (oldWork: WorkModel, update: UpdateStatusModel) => {
        if (oldWork._id !== update.work_id) return oldWork;

        const updatedProgress = oldWork.progress.map((progress) => {
          if (progress.student_id !== update.student_id) return progress;

          const theNextStatus = nextStatus(update.oldStatus);

          updateClassWorkStudentProgress(state.classroom._id, update.work_id, update.student_id, theNextStatus);
          const logDetails = {
            time: new Date().toLocaleTimeString(),
            teacher_id: state.classroom.teacher_id,
            work_id: update.work_id,
            student_id: update.student_id,
            from_progress: update.oldStatus,
            to_progress: theNextStatus,
          }
          addDayLog(state.classroom.school_id, state.classroom._id, logDetails, new Date().toLocaleDateString());

          return { ...progress, status: theNextStatus };
        });

        return { ...oldWork, progress: updatedProgress };
      };

      state.classroom.works = state.classroom.works.map(work => updateProgressInWork(work, action.payload));
      state.classroom.worksShown = state.classroom.works.filter(work => work.subject === state.classroom.activeSubject);
    },
    setActiveSubject: (state, action: PayloadAction<ClassroomState['classroom']['activeSubject']>) => {
      console.log("Setting active subject");
      state.classroom.activeSubject = action.payload;
      state.classroom.worksShown = state.classroom.works.filter(work => work.subject === action.payload);
    },
    toggleActiveStudentNotes: (state, action: PayloadAction<ClassroomState['classroom']['activeStudentNotes']>) => {
      console.log("toggling active student notes");
      if (state.classroom.activeStudentNotes === action.payload) {
        state.classroom.activeStudentNotes = '';
      } else {
        state.classroom.activeStudentNotes = action.payload;
      }
    },
    setWorkNotes: (state, action: PayloadAction<UpdateNotesModel>) => {
      const updateProgressNotesInWork = (oldWork: WorkModel, update: UpdateNotesModel) => {
        if (oldWork._id !== update.work_id) return oldWork;
    
        const updatedProgress = oldWork.progress.map((progress) => {
          if (progress.student_id !== update.student_id) return progress;
    
          updateClassWorkStudentProgressNotes(state.classroom._id, update.work_id, update.student_id, update.newNotes);
          const logDetails = {
            time: new Date().toLocaleTimeString(),
            teacher_id: state.classroom.teacher_id,
            work_id: update.work_id,
            student_id: update.student_id,
            from_notes: progress.notes,
            to_notes: update.newNotes,
          }
          addDayLog(state.classroom.school_id, state.classroom._id, logDetails, new Date().toLocaleDateString());
    
          return { ...progress, notes: update.newNotes };
        });
    
        return { ...oldWork, progress: updatedProgress };
      };
    
      state.classroom.works = state.classroom.works.map(work => updateProgressNotesInWork(work, action.payload));
      state.classroom.worksShown = state.classroom.works.filter(work => work.subject === state.classroom.activeSubject);
    },
    toggleActiveCurriculumYear: (state, action: PayloadAction<ClassroomState['classroom']['activeCurriculumYear']>) => {
      if ( action.payload && state.classroom.activeCurriculumYear !== action.payload) {
        state.classroom.activeCurriculumYear = action.payload;
        state.classroom.studentsShown = state.classroom.students.filter((student: StudentModel) => student.yearInCurriculum === action.payload);
      } else {
        state.classroom.activeCurriculumYear = null;
        state.classroom.studentsShown = state.classroom.students;
      }
    },
    setActiveStudent: (state, action: PayloadAction<ClassroomState['classroom']['activeStudent']>) => {
      console.log("Setting active student");
      state.classroom.activeStudent = action.payload;
    },
  },
});

export const { setClassroom, setWorks, setWork, setWorkNotes, toggleActiveStudentNotes, setActiveSubject, toggleActiveCurriculumYear, setActiveStudent } = classroomSlice.actions;

export default classroomSlice.reducer;

function nextStatus(status: Status): any {
  switch (status) {
    case Status.Unplanned:
      return Status.Planned;
    case Status.Planned:
      return Status.GaveALesson;
    case Status.GaveALesson:
      return Status.WorkingOnIt;
    case Status.WorkingOnIt:
      return Status.Mastered;
    case Status.Mastered:
      return Status.Unplanned;
    default:
      return Status.Planned;
  }
}