import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store.ts";
import { StudentData } from "../../types.ts";
import WorkRopeTableStudentTh from "./WorkRopeTableStudentTh.tsx";
import "./WorkRopeTable.css";
import { selectFilteredStudents } from "../../slices/selectors.ts";

const WorkRopeTableHeader: React.FC = () => {
  const students = useSelector(selectFilteredStudents);

  if (!students) {
    return <div />;
  }

  return (
    <thead>
      <tr>
        <th className="studentTh workName mainWorkName">Works</th>
        {students.map((student: StudentData) => (
          <WorkRopeTableStudentTh key={student.refPath} student={student} />
        ))}
      </tr>
    </thead>
  );
};

export default WorkRopeTableHeader;
