import React from "react";
import { StudentData } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import NewWorkRopeBead from "../NewWorkRopeBead.tsx";
import { selectFilteredStudents } from "../../slices/selectors.ts";
import {
  createProgressRecord,
  updateProgressRecord,
} from "../../slices/progressSlice.ts";
import { setActiveSubject } from "../../slices/studentSlice.ts";
import EditableWorkName from "./EditableWorkName.tsx";
import { useDisplayedWorks } from "../../hooks/useDisplayedWorks.tsx";
import WorkRopeBeadWithNoteCell from "./WorkRopeBeadWithNoteCell.tsx";
import SummaryBeadWithNoteCell from "./SummaryBeadWithNoteCell.tsx";
import WorkGroupName from "./WorkGroupName.tsx";

const WorkRopeTableBody: React.FC = () => {
  const curriculum = useSelector((state: RootState) => state.works.curriculum);
  const subject = useSelector(
    (state: RootState) => state.student.activeSubject
  );
  const works = curriculum[subject];

  const students = useSelector(selectFilteredStudents);
  const dispatch = useDispatch();

  const progress = useSelector((state: RootState) => state.progress.records);

  const { getVisibleWorks, toggleGroupVisibility, worksToGroups } =
    useDisplayedWorks();

  if (subject == undefined) {
    dispatch(setActiveSubject("Practical Life"));
    console.log("bad bug fix");
  }
  if (!students || !works) {
    return <div />;
  }

  const worksToDisplay = getVisibleWorks();

  return (
    <tbody>
      {worksToDisplay.map((work, work_index) => {
        if ("workRefPath" in work) {
          return (
            <tr key={work_index}>
              <EditableWorkName
                workName={works[work.workIndex]}
                workRefPath={work.workRefPath}
                workIndex={work_index}
                indented={work.workName in worksToGroups}
              />
              {students.map((student: StudentData) => {
                const singleProgress = progress.find((prog) => {
                  return (
                    prog.studentRefPath == student.refPath &&
                    prog.workRefPath == work.workRefPath
                  );
                });

                return (
                  <WorkRopeBeadWithNoteCell
                    singleProgress={singleProgress}
                    work={work}
                    student={student}
                  />
                );
              })}
            </tr>
          );
        } else {
          return (
            <tr key={work_index}>
              <WorkGroupName
                name={work.groupName}
                toggle={toggleGroupVisibility}
                visible={work.visible}
              />
              {students.map((student: StudentData) => {
                return (
                  <SummaryBeadWithNoteCell
                    works={work.works}
                    groupName={work.groupName}
                    toggle={toggleGroupVisibility}
                    studentRefPath={student.refPath}
                  />
                );
              })}
            </tr>
          );
        }
      })}
    </tbody>
  );
};

export default WorkRopeTableBody;
