import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { StudentData } from "../../types";
import { selectPlannedWorksForStudent } from "../../slices/selectors.ts";

interface PlannedWorksPopupProps {
  student: StudentData;
}

const PlannedWorksPopup: React.FC<PlannedWorksPopupProps> = ({ student }) => {
  const plannedWorks = useSelector((state: RootState) =>
    selectPlannedWorksForStudent(state, student)
  );

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        backgroundColor: "white",
        border: "solid black 1px",
        width: "200px",
        transform: `translateX(${-100 + 40}px)`,
        zIndex: 5,
      }}
    >
      Planned:
      <br />
      {plannedWorks.length > 0 ? (
        plannedWorks.map((work) => {
          return (
            <p key={work} style={{ fontWeight: "normal" }}>
              {work}
            </p>
          );
        })
      ) : (
        <p style={{ fontWeight: "normal" }}>No works planned</p>
      )}
    </div>
  );
};

export default PlannedWorksPopup;
