// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5i5uLoXeG-hZwzoyq8VdK_OgIj4Fulfw",
  authDomain: "montessorisoftware-a344f.firebaseapp.com",
  projectId: "montessorisoftware-a344f",
  storageBucket: "montessorisoftware-a344f.appspot.com",
  messagingSenderId: "929367380964",
  appId: "1:929367380964:web:2bb574ba73e0d50fd76df1",
  measurementId: "G-9NVJLM42PQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const a = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;

