import React from "react";
import SubjectTab from "./SubjectTab.tsx";
import "./SubjectTabsContainer.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store.ts";
import CurriculumYearButtons from "./CurriculumYearButtons.tsx";
import EditModeButton from "./EditModeButton.tsx";

const SubjectTabsContainer: React.FC = () => {
  const subjects = useSelector(
    (state: RootState) => state.works.curriculumOrder
  );
  const username = useSelector(
    (state: RootState) => state.firestoreTwo.teacherName
  );

  return (
    <div className="subjectTabsContainer">
      <span className="pageTitle">{username}'s Notebook</span>
      <div>
        {subjects.map((subject) => (
          <SubjectTab key={subject} subject={subject} />
        ))}
      </div>
      <div>
        <CurriculumYearButtons />
        <EditModeButton />
      </div>
    </div>
  );
};

export default SubjectTabsContainer;
