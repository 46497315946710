import React from "react";

import SubjectTabsContainer from "../components/SubjectTabsContainer/SubjectTabsContainer.tsx";
import WorkRopeTableHeader from "../components/WorkRopeTable/WorkRopeTableHeader.tsx";
import WorkRopeTableBody from "../components/WorkRopeTable/WorkRopeTableBody.tsx";

import { useFirebaseAuthSync } from "../hooks/useFirebaseAuth.tsx";

import "./SnapshotView.css";
import { useSelector } from "react-redux";
import { RootState } from "../store.ts";
import { useFirestoreDb } from "../hooks/useFirestoreDb.tsx";
import { useProgress } from "../hooks/useProgress.tsx";
import { ModalProvider } from "../components/MyModal.tsx";
import { useWorks } from "../hooks/useWorks.tsx";
import { useSettings } from "../hooks/useSettings.tsx";

const SnapshotView = () => {
  useFirebaseAuthSync();
  //useClassroomData();
  useFirestoreDb();
  useProgress();
  useWorks();
  useSettings();

  const classroom = useSelector(
    (state: RootState) => state.classroom.classroom
  );

  if (!classroom) {
    return <div>Loading...</div>;
  }

  return (
    <ModalProvider>
      <div className="sidebarPage">
        <SubjectTabsContainer />
        <div className="table-container">
          <table cellSpacing="0" cellPadding="0">
            {<WorkRopeTableHeader />}
            {<WorkRopeTableBody />}
          </table>
        </div>
      </div>
    </ModalProvider>
  );
};

export default SnapshotView;
