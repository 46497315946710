import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store.ts";
import "./StudentPage.css";
import { StudentPageTableHeader } from "./StudentPageTableHeader.tsx";
import { StudentPageRows } from "./StudentPageRows.tsx";
import { BackToHomePageButton } from "./BackToHomePageButton.tsx";
import { toggleNotesShown } from "../../slices/studentSlice.ts";
import DebouncedTextArea from "./DebouncedTextArea.tsx";
import { useCommentSections } from "../../hooks/useCommentSections.tsx";
import { ModalProvider } from "../../components/MyModal.tsx";

const StudentPage = () => {
  const activeStudent = useSelector(
    (state: RootState) => state.student.activeStudentForStudentPage
  );

  const dispatch = useDispatch();
  useCommentSections();

  return (
    <ModalProvider>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "20px",
          }}
        >
          <h1 style={{ paddingLeft: "10px" }}>{activeStudent?.name}'s Page</h1>
          <div>
            <button
              style={{ marginRight: "10px" }}
              onClick={() => {
                dispatch(toggleNotesShown());
              }}
            >
              Show/Hide Notes
            </button>

            <BackToHomePageButton />
          </div>
        </div>

        <div style={{ padding: "20px", paddingTop: "0px" }}>
          <span style={{ fontWeight: "bold" }}>General Comment Section</span>
          <DebouncedTextArea subject={"General"} />
        </div>

        <table cellSpacing="0" cellPadding="0">
          <thead>
            <StudentPageTableHeader />
          </thead>
          <tbody>
            <StudentPageRows />
          </tbody>
        </table>
      </div>
    </ModalProvider>
  );
};

export default StudentPage;
