import { CurriculumAndWorks, ProgressData, StudentData, TeacherNameResponse } from './types';

export const fetchStudentsForTeacher = async (teacher_uid: string): Promise<StudentData[]> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${teacher_uid}/students`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.data as StudentData[];
    } catch (error) {
      console.error("Error fetching students for teacher:", error);
      return []; // Return an empty array as a fallback
    }
  };
  
  export const fetchProgressBySubject = async (teacher_uid: string, subject: string): Promise<ProgressData[]> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${teacher_uid}/progress/${subject}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.progress as ProgressData[];
    } catch (error) {
      console.error("Error fetching progress records:", error);
      return []; // Return an empty array as a fallback
    }
  };
  
  export const fetchWorksForSchool = async (teacher_uid: string): Promise<CurriculumAndWorks> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/users/${teacher_uid}/works`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: CurriculumAndWorks = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching works for teacher:", error);
      return { curriculum: {}, worksMap: {}, curriculumOrder: [], curriculumRefPaths: {} };
    }
  };
  
  export const fetchTeacherName = async (teacher_uid: string): Promise<TeacherNameResponse> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/getTeacherName/${teacher_uid}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data: TeacherNameResponse = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error('Error fetching teacher name:', error);
      return {name: ""}; // Handle errors or throw as appropriate
    }
  }