// src/middleware/firestoreSyncMiddleware.ts
import { Middleware } from '@reduxjs/toolkit';
import { db } from '../firebase.ts';
import { doc, onSnapshot } from 'firebase/firestore';
import { setTeacherName, stopTeacherNameSubscription } from '../slices/firestoreSliceTwo.ts';

interface TeacherNameAction {
    type: 'user/teacherName';
    payload: string;
  }

// Create a type guard to check if an action is of type TeacherNameAction
function isTeacherNameAction(action: any): action is TeacherNameAction {
    return action.type === 'user/teacherName';
}

let unsubscribeCurrentListener: (() => void) | null = null;

// Assuming you have an action to set the real-time teacher name
export const firestoreSyncMiddleware: Middleware = (store) => (next) => (action) => {
    if (isTeacherNameAction(action)) {
        // No need for casting, `action` is now `TeacherNameAction` within this block
        const userId = action.payload;
        const docRef = doc(db, 'Users', userId);
    
        unsubscribeCurrentListener = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const name = doc.data()?.name || 'Unknown';
            store.dispatch(setTeacherName(name));
          }
        });
    } else if (stopTeacherNameSubscription.match(action)) {
        // Action to stop listening
        if (unsubscribeCurrentListener) {
            unsubscribeCurrentListener();
            unsubscribeCurrentListener = null;
        }
    }
    return next(action);
};