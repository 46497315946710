// src/slices/progressSlice.ts
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { CommentSectionData, ProgressData } from '../types';

interface ProgressState {
  records: ProgressData[];
  commentSections: CommentSectionData[];
}

const initialState: ProgressState = {
  records: [],
  commentSections: []
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgressRecords(state, action: PayloadAction<ProgressData[]>) {
        state.records = action.payload;
    },
    setComments(state, action: PayloadAction<CommentSectionData[]>) {
        state.commentSections = action.payload;
    }
  },
});

export const { setProgressRecords, setComments } = progressSlice.actions;

// Action to trigger the middleware for subscription
export const triggerFetchProgress = createAction<string>('progress/fetchStart');
export const stopFetchProgress = createAction('progress/fetchStop');
export const updateProgressRecord = createAction<{
    teacherUid: string,
    progressId: string,
    updates: Partial<ProgressData>,
    olds: Partial<ProgressData>
  }>('progress/updateRecord');
  
export const createProgressRecord = createAction<{
    teacherUid: string,
    newProgress: ProgressData
  }>('progress/createRecord');

  // Define additional actions for CommentSections
export const triggerFetchComments = createAction<string>('comments/fetchStart');
export const stopFetchComments = createAction('comments/fetchStop');
export const updateComment = createAction<{
  teacherUid: string,
  commentId: string,
  updates: Partial<{subject: string; text: string; studentRef: string;}>;
  olds: Partial<{subject: string; text: string; studentRef: string;}>;
}>('comments/update');
export const createComment = createAction<{
  teacherUid: string,
  newComment: {subject: string; text: string; studentRef: string;};
}>('comments/create');

export default progressSlice.reducer;
