import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth"; // Assume this is your user type
import { UserModel } from "../types";

interface UserState {
  user: {
    id: string | null;
    email: string | null;
    displayName: string | null;
  };
  message: string | null;
}

const initialState: UserState = {
  user: {
    id: "",
    email: null,
    displayName: null,
  },
  message: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user.id = action.payload?.id;
      state.user.displayName = action.payload
        ? action.payload.email.split("@")[0][0].toUpperCase() +
          action.payload.email.split("@")[0].substring(1)
        : "";
      state.user.email = action.payload.email;
      state.message = null;
    },
    loginUserFailure: (state, action: PayloadAction<UserState["message"]>) => {
      state.user.id = null;
      state.user.email = null;
      state.user.displayName = null;
      state.message = action.payload;
    },
  },
});

export const { setUser, loginUserFailure } = userSlice.actions;

export default userSlice.reducer;
