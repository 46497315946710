import { Middleware } from "redux";
import { triggerFetchSettings, stopFetchSettings, setProgressSymbolsSetting } from "../slices/settingsSlice.ts";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.ts";

let unsubscribe: (() => void) | null = null;

export const settingsMiddleware: Middleware = store => next => async action => {
    next(action);

    if (triggerFetchSettings.match(action)) {
        const teacherUid = action.payload;
        const teacherDocRef = doc(db, 'Users', teacherUid);

        try {
            const teacherDoc = await getDoc(teacherDocRef);

            if (!teacherDoc.exists()) {
                console.log("Teacher not found.");
                return;
            }

            const schoolRef = teacherDoc.data().school;
            if (!schoolRef) {
                console.log("School reference not found in teacher's document.");
                return;
            }

            unsubscribe = onSnapshot(schoolRef, (schoolDocSnapshot) => {
                if (!schoolDocSnapshot.exists()) {
                    console.log("School document not found.");
                    return;
                }

                const settingsData = schoolDocSnapshot.data().settings;
                if (settingsData && settingsData.progressSymbols) {
                    store.dispatch(setProgressSymbolsSetting(settingsData.progressSymbols));
                }
            });
        } catch (error) {
            console.error("Error retrieving settings:", error);
        }
    } else if (stopFetchSettings.match(action)) {
        if (unsubscribe) {
            unsubscribe();
            unsubscribe = null;
        }
    }
};
