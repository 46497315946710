import React from "react";
import DebouncedTextArea from "./DebouncedTextArea.tsx";

interface SubjectCommentSectionProps {
  rowsForTextArea: number;
  index: number;
  subject_index: number;
  subject: string;
}

export const SubjectCommentSection: React.FC<SubjectCommentSectionProps> = ({
  rowsForTextArea,
  index,
  subject,
  subject_index,
}) => {
  return (
    <td
      colSpan={2}
      rowSpan={1 + rowsForTextArea}
      key={`work-${index}-${subject_index}`}
      id={`work-${index}-${subject_index}`}
    >
      <div style={{ height: "100%", padding: "5px" }}>
        <span style={{ fontWeight: "bold" }}>{subject} Comment Section</span>
        <DebouncedTextArea subject={subject} />
      </div>
    </td>
  );
};
