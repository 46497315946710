import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const StudentPageTableHeader: React.FC = () => {
  const subjects = useSelector(
    (state: RootState) => state.works.curriculumOrder
  );

  return (
    <tr>
      {subjects.map((subject: string) => {
        return (
          <React.Fragment key={subject}>
            <th />
            <th style={{ maxWidth: "200px", minWidth: "200px" }} key={subject}>
              {subject}
            </th>
          </React.Fragment>
        );
      })}
    </tr>
  );
};
