import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Work, WorkGroup } from "../slices/worksSlice";
import { useState } from "react";

type WorkOrGroup = Work | WorkGroup;

export const useDisplayedWorks = () => {
  const curriculum = useSelector((state: RootState) => state.works.curriculum);
  const curriculumGroups = useSelector(
    (state: RootState) => state.works.curriculumGroups
  );
  const curriculumRefPaths = useSelector(
    (state: RootState) => state.works.curriculumRefPaths
  );
  const subject = useSelector(
    (state: RootState) => state.student.activeSubject
  );
  const works = curriculum[subject];
  const workRefPaths = curriculumRefPaths[subject];
  const [workGroups, setWorkGroups] = useState(
    curriculumGroups?.[subject] || []
  );
  console.log(workGroups);

  let groupsSeenSoFar: string[] = [];
  const [groupsExpanded, setGroupsExpanded] = useState<{
    [key: string]: boolean;
  }>({});

  const isFirstOccurrence = (item: string) => {
    return groupsSeenSoFar.filter((val) => val == item).length === 0;
  };

  const toggleGroupVisibility = (group: string) => {
    setGroupsExpanded((old) => ({ ...old, [group]: !old[group] }));
    setWorkGroups((currentWorkGroups) =>
      currentWorkGroups.map((g) =>
        g.groupName === group ? { ...g, visible: !g.visible } : g
      )
    );
  };

  const worksToGroups: { [key: string]: string } = {};

  if (workGroups) {
    workGroups.forEach((group) => {
      group.works.forEach(
        (work) => (worksToGroups[work.workName] = group.groupName)
      );
    });
  }

  const getVisibleWorks = (): WorkOrGroup[] => {
    groupsSeenSoFar = [];
    const visibleWorks: WorkOrGroup[] = [];
    works.forEach((workName, workIndex) => {
      const group = worksToGroups[workName];
      if (group) {
        if (isFirstOccurrence(group) && !groupsExpanded[group]) {
          groupsSeenSoFar.push(group);
          groupsExpanded[group] = false;
          visibleWorks.push(
            workGroups.filter((aGroup) => aGroup.groupName === group)[0]
          );
        }
        if (!groupsExpanded[group]) {
          return;
        }
        if (isFirstOccurrence(group) && groupsExpanded[group]) {
          visibleWorks.push(
            workGroups.filter((aGroup) => aGroup.groupName === group)[0]
          );
          groupsSeenSoFar.push(group);
        }
      }
      visibleWorks.push({
        workName,
        workIndex,
        workRefPath: workRefPaths[workIndex],
      });
    });

    return visibleWorks;
  };

  return { getVisibleWorks, toggleGroupVisibility, worksToGroups };
};
