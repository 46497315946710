import React, { useState } from "react";
import Sidebar from "./Sidebar.tsx";
import { Outlet } from "react-router-dom";
import "./Sidebar.css";

const SidebarLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="layout">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div id="main" className={isOpen ? "open" : "closed"}>
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;