import React from "react";
import { useNavigate } from "react-router-dom";

export const BackToHomePageButton: React.FC = () => {
  let navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/"); // Use navigate to change the route
  };

  return <button onClick={goToHomePage}>Back to Class Page</button>;
};
