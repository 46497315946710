import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  triggerFetchProgress,
  stopFetchProgress,
} from "../slices/progressSlice.ts";
import { RootState } from "../store";

export const useProgress = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(triggerFetchProgress(user.id as string));
    return () => {
      dispatch(stopFetchProgress());
    };
  }, [user, dispatch]);
};
