import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { StudentData } from "../../types";
import React from "react";

interface NotesColumnHeaderProps {
  student: StudentData;
}

const NotesColumnHeader: React.FC<NotesColumnHeaderProps> = ({ student }) => {
  const activeStudent = useSelector(
    (state: RootState) => state.student.activeStudent
  );
  if (activeStudent?.refPath === student.refPath) {
    return <th className="clickableTh graySubtitle">notes</th>;
  } else {
    return <></>;
  }
};

export default NotesColumnHeader;
