// src/components/Sidebar.js
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { useDispatch } from "react-redux";
import { setUser } from "../slices/userSlice.ts";
import { UserModel } from "../types";
import { auth } from "../firebase.ts";
import {
  stopFetchComments,
  stopFetchProgress,
} from "../slices/progressSlice.ts";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogOut = async (e) => {
    e.preventDefault();
    await auth.signOut();
    dispatch(stopFetchProgress());
    dispatch(stopFetchComments());
    navigate("/login");
  };

  auth.onAuthStateChanged(function (user) {
    if (user && user.email) {
      const usermodel: UserModel = {
        id: user?.uid,
        email: user?.email,
        displayName:
          user?.email.split("@")[0][0].toUpperCase() +
          user?.email.split("@")[0].substring(1),
      };
      dispatch(setUser(usermodel));
    } else {
      console.log("no user in signed in");
      dispatch(stopFetchProgress());
      dispatch(stopFetchComments());
      navigate("/login");
    }
  });

  useEffect(() => {}, [auth]);

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`} id="mySidebar">
      <div
        className={`nav-toggle ${isOpen ? "open" : "closed"}`}
        onClick={toggleSidebar}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div id="linkList" className={isOpen ? "show" : "hide"}>
        <ul style={{ padding: 0, whiteSpace: "nowrap" }}>
          <li>
            <Link to="/">Home Page</Link>
          </li>
          <li>
            <Link to="/resources">Resources Page</Link>
          </li>
          <li>
            <Link to="/student">Student Page</Link>
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button onClick={onLogOut}>Log Out</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
