import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  stopFetchSettings,
  triggerFetchSettings,
} from "../slices/settingsSlice.ts";

export const useSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(triggerFetchSettings(user.id as string));
    return () => {
      dispatch(stopFetchSettings());
    };
  }, [user, dispatch]);
};
