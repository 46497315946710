import { useEffect, useState } from "react";
import {
  Dud,
  FullTriangle,
  OnePartOfTriangle,
  TwoPartsOfTriangleLR,
  TwoPartsOfTriangleLB,
} from "../icons/Triangles";
import getPastelColor, {
  getBlankColor,
  getColor33,
  getColor67,
} from "../utilities";
import { useLongPress } from "use-long-press";
import "./WorkRopeBead.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProgressRecord,
  updateProgressRecord,
} from "../slices/progressSlice.ts";
import { RootState } from "../store";
import { Status } from "../types.ts";
import { useModal } from "./MyModal.tsx";
import { ProgressSymbol } from "../slices/settingsSlice.ts";

interface WorkRopeBeadProps {
  status: string;
  subject: string;
  small?: boolean;
  withNoteIcon?: boolean;
  progressRefPath: string | undefined;
  workRefPath: string;
  studentRefPath: string;
  studentName: string;
  workName: string;
}

const NewWorkRopeBead: React.FC<WorkRopeBeadProps> = ({
  status,
  subject,
  progressRefPath,
  workRefPath,
  studentRefPath,
  small = false,
  withNoteIcon = false,
  studentName,
  workName,
}) => {
  const statusToColorMap = {
    Unknown: getBlankColor,
    Postponed: getBlankColor,
    Unplanned: getBlankColor,
    Planned: getBlankColor,
    "Gave a lesson": getPastelColor,
    "Working on it": getColor33,
    Mastered: getColor67,
  };

  const progressSymbols = useSelector(
    (state: RootState) => state.settings.progressSymbols
  );

  const progressSymbolsToSVG = () => {
    if (progressSymbols == ProgressSymbol.LRB) {
      return TwoPartsOfTriangleLR;
    } else if (progressSymbols == ProgressSymbol.LBR) {
      return TwoPartsOfTriangleLB;
    } else {
      return TwoPartsOfTriangleLR;
    }
  };

  const statusToSVGmap = {
    Unknown: Dud,
    Postponed: Dud,
    Unplanned: Dud,
    Planned: Dud,
    "Gave a lesson": OnePartOfTriangle,
    "Working on it": progressSymbolsToSVG(),
    Mastered: FullTriangle,
  };

  // Extract or compute any necessary data from props here
  const backgroundColor = statusToColorMap[status](subject);
  const StatusSVG = statusToSVGmap[status];
  const beadClass = `${status === "Unplanned" ? "Unplanned" : ""}Bead`;

  const [activeClick, setActiveClick] = useState(false);
  const [longPress, setLongPress] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const modal = useModal();

  const [shiftHeld, setShiftHeld] = useState(false);
  const [nextStatusWouldBe, setNextStatusWouldBe] = useState("");

  function downHandler({ key }) {
    if (key === "3") {
      setShiftHeld(true);
      setNextStatusWouldBe("Mastered");
    } else if (key === "2") {
      setShiftHeld(true);
      setNextStatusWouldBe("Working on it");
    } else if (key === "1") {
      setShiftHeld(true);
      setNextStatusWouldBe("Gave a lesson");
    }
  }
  function upHandler({ key }) {
    if (key === "3") {
      setShiftHeld(false);
      setNextStatusWouldBe("");
    } else if (key === "2") {
      setShiftHeld(false);
      setNextStatusWouldBe("");
    } else if (key === "1") {
      setShiftHeld(false);
      setNextStatusWouldBe("");
    }
  }
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  const onLongPress = () => {
    setLongPress(true);
  };

  const bind = useLongPress(onLongPress, {
    threshold: 300,
    onStart: (event) => setActiveClick(true),
    onFinish: (event) => {
      setActiveClick(false);
      setLongPress(false);
    },
    onCancel: (event) => {},
    captureEvent: true,
  });

  const handleClickIntermediary = async () => {
    if (activeClick && !longPress) {
      if (status === "Mastered") {
        const confirmed = await modal.openModal(
          `Mark ${studentName}'s Mastered ${workName} as Unplanned?`
        );
        if (!confirmed) {
          console.log("Cancelled!");
          return; // Exit the function if cancelled
        }
        console.log("Confirmed!");
      }

      let theNextStatus = nextStatus(status as Status);
      if (shiftHeld) {
        theNextStatus = nextStatusWouldBe;
      }
      if (progressRefPath) {
        dispatch(
          updateProgressRecord({
            teacherUid: user?.id as string,
            progressId: progressRefPath,
            updates: { status: theNextStatus },
            olds: { status: status },
          })
        );
      } else {
        dispatch(
          createProgressRecord({
            teacherUid: user?.id as string,
            newProgress: {
              id: "",
              status: theNextStatus,
              notes: "",
              subject: subject,
              studentRefPath: studentRefPath,
              workRefPath: workRefPath,
            },
          })
        );
      }
      setActiveClick(false);
    } else {
      setLongPress(false);
    }
  };

  return (
    <td
      onClick={() => handleClickIntermediary()}
      {...bind()}
      className="tdMinWidth"
      key={""}
    >
      <div className={`BeadContainer ${small && "SmallBeadContainer"}`}>
        {withNoteIcon && (
          <div className="noteIcon" style={{ backgroundColor }}></div>
        )}
        <div className={beadClass} style={{ backgroundColor }}>
          <StatusSVG />
        </div>
      </div>
    </td>
  );
};

export default NewWorkRopeBead;

function nextStatus(status: Status): any {
  switch (status) {
    case Status.Unplanned:
      return Status.Planned;
    case Status.Planned:
      return Status.GaveALesson;
    case Status.GaveALesson:
      return Status.WorkingOnIt;
    case Status.WorkingOnIt:
      return Status.Mastered;
    case Status.Mastered:
      return Status.Unplanned;
    default:
      return Status.Planned;
  }
}
