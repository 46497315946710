// src/slices/worksSlice.ts
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

export type Work = {
    workName: string,
    workRefPath: string,
    workIndex: number,
};

export type WorkGroup = {
    groupRefPath: string,
    groupName: string,
    visible: boolean,
    works: Work[],
};

interface WorksState {
    curriculum: { [key: string]: string[] };
    curriculumRefPaths: { [key: string]: string[] };
    curriculumOrder: string[],
    curriculumGroups: { [key: string]: WorkGroup[] };
}

const initialState: WorksState = {
    curriculum: {},
    curriculumRefPaths: {},
    curriculumOrder: [],
    curriculumGroups: {}
};

export const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    setCurriculum(state, action: PayloadAction<WorksState['curriculum']>) {
        state.curriculum = action.payload;
    },
    setCurriculumRefPaths(state, action: PayloadAction<WorksState['curriculumRefPaths']>) {
        state.curriculumRefPaths = action.payload;
    },
    setCurriculumOrder(state, action: PayloadAction<WorksState['curriculumOrder']>) {
        state.curriculumOrder = action.payload;
    },
    setCurriculumGroups(state, action: PayloadAction<WorksState['curriculumGroups']>) {
        state.curriculumGroups = action.payload;
    },
  },
});

export const { setCurriculum, setCurriculumRefPaths, setCurriculumOrder, setCurriculumGroups } = worksSlice.actions;

// Action to trigger the middleware for subscription
export const triggerFetchWorks = createAction<string>('works/fetchStart');
export const stopFetchWorks = createAction('works/fetchStop');

export default worksSlice.reducer;
