// src/slices/firestoreSlice.ts
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const triggerTeacherNameSubscription = createAction<string>('user/teacherName');
export const stopTeacherNameSubscription = createAction('user/stopTeacherNameSubscription');

interface FirestoreState {
  teacherName: string;
  // Other state properties
}

const initialState: FirestoreState = {
  teacherName: '',
  // Initial values for other state properties
};

export const firestoreSliceTwo = createSlice({
  name: 'firestore',
  initialState,
  reducers: {
    setTeacherName(state, action: PayloadAction<string>) {
      state.teacherName = action.payload;
    },
    // Other reducers...
  },
  // Consider removing extraReducers if you're handling real-time updates via middleware
});

export const { setTeacherName } = firestoreSliceTwo.actions;
export default firestoreSliceTwo.reducer;
