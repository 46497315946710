// hooks/useFirebaseAuthSync.ts
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged, User } from 'firebase/auth';
import { setUser } from '../slices/userSlice.ts';
import { UserModel } from '../types.ts';
import { auth } from '../firebase.ts';

export const useFirebaseAuthSync = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      if (!user || !user.email) {
        return ;
      }
      const usermodel: UserModel = {
        id: user?.uid,
        email: user?.email,
        displayName: user?.email.split("@")[0][0].toUpperCase() + user?.email.split("@")[0].substring(1)
      }
      // Dispatch setUser action to update the user state in the Redux store
      dispatch(setUser(usermodel));
    });

    return () => unsubscribe();
  }, [auth]);
};
