import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchProgressBySubject, fetchStudentsForTeacher, fetchTeacherName, fetchWorksForSchool } from "../api.ts";
import { StudentData, WorkData, ProgressData, CurriculumAndWorks, TeacherNameResponse } from "../types";

export const fetchStudentsForTeacherAsync = createAsyncThunk<StudentData[], string, { rejectValue: string }>(
    'firestore/fetchStudents',
    async (teacherId, { rejectWithValue }) => {
      try {
        const studentData = await fetchStudentsForTeacher(teacherId);
        return studentData;
      } catch (error) {
        return rejectWithValue('Failed to fetch student data');
      }
    }
);

export const fetchProgressBySubjectAsync = createAsyncThunk<ProgressData[], { teacherId: string, subject: string }, { rejectValue: string }>(
    'firestore/fetchProgressBySubject',
    async ({ teacherId, subject }, { rejectWithValue }) => {
      try {
        const progressData = await fetchProgressBySubject(teacherId, subject);
        return progressData;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
);

export const fetchWorksForSchoolAsync = createAsyncThunk<CurriculumAndWorks, string, { rejectValue: string }>(
    'firestore/fetchWorksForSchool',
    async (teacherId, { rejectWithValue }) => {
      try {
        const worksData = await fetchWorksForSchool(teacherId);
        return worksData;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
);

export const fetchTeacherNameAsync = createAsyncThunk<TeacherNameResponse, string, { rejectValue: string }>(
    'firestore/fetchTeacherName',
    async (teacherId, { rejectWithValue }) => {
      try {
        const teacherName = await fetchTeacherName(teacherId);
        return teacherName;
      } catch (error) {
        return rejectWithValue(error.toString());
      }
    }
);

interface FirestoreState {
    students: StudentData[],
    curriculum: { [key: string]: string[] };
    curriculumRefPaths: { [key: string]: string[] };
    curriculumOrder: string[],
    worksMap: { [key: string]: WorkData };
    progress: ProgressData[],
    teacherName: string,
    thunks: { fetchStudentsLoading: boolean, fetchStudentsError: string | null,
        fetchProgressLoading: boolean, fetchProgressError: string | null,
        fetchWorksLoading: boolean, fetchWorksError: string | null,
        fetchTeacherNameLoading: boolean, fetchTeacherNameError: string | null }
}

const initialState: FirestoreState = {
    students: [],
    curriculum: {},
    curriculumOrder: [],
    curriculumRefPaths: {},
    worksMap: {},
    progress: [],
    teacherName: "",
    thunks: {
        fetchStudentsLoading: false,
        fetchStudentsError: null,
        fetchProgressLoading: false,
        fetchProgressError: null,
        fetchWorksLoading: false,
        fetchWorksError: null,
        fetchTeacherNameLoading: false,
        fetchTeacherNameError: null
    }
}

export const firestoreSlice = createSlice({
    name: 'firestore',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
          .addCase(fetchStudentsForTeacherAsync.pending, (state) => {
            state.thunks.fetchStudentsLoading = true;
            state.thunks.fetchStudentsError = null;
          })
          .addCase(fetchStudentsForTeacherAsync.fulfilled, (state, action) => {
            state.students = action.payload;
            state.thunks.fetchStudentsLoading = false;
          })
          .addCase(fetchStudentsForTeacherAsync.rejected, (state, action) => {
            state.thunks.fetchStudentsError = action.payload as string | null;
            state.thunks.fetchStudentsLoading = false;
          })
          .addCase(fetchProgressBySubjectAsync.pending, (state) => {
            state.thunks.fetchProgressLoading = true;
            state.thunks.fetchProgressError = null;
          })
          .addCase(fetchProgressBySubjectAsync.fulfilled, (state, action) => {
            state.progress = action.payload;
            state.thunks.fetchProgressLoading = false;
          })
          .addCase(fetchProgressBySubjectAsync.rejected, (state, action) => {
            state.thunks.fetchProgressError = action.payload as string | null;
            state.thunks.fetchProgressLoading = false;
          })
          // Cases for fetchWorksForSchoolAsync
          .addCase(fetchWorksForSchoolAsync.pending, (state) => {
            state.thunks.fetchWorksLoading = true;
            state.thunks.fetchWorksError = null;
          })
          .addCase(fetchWorksForSchoolAsync.fulfilled, (state, action) => {
            state.worksMap = action.payload.worksMap;
            state.curriculum = action.payload.curriculum;
            state.curriculumRefPaths = action.payload.curriculumRefPaths
            state.curriculumOrder = action.payload.curriculumOrder;
            state.thunks.fetchWorksLoading = false;
          })
          .addCase(fetchWorksForSchoolAsync.rejected, (state, action) => {
            state.thunks.fetchWorksError = action.payload as string | null;
            state.thunks.fetchWorksLoading = false;
          })
          // Cases for fetchTeacherNameAsync
          .addCase(fetchTeacherNameAsync.pending, (state) => {
            state.thunks.fetchTeacherNameLoading = true;
            state.thunks.fetchTeacherNameError = null;
          })
          .addCase(fetchTeacherNameAsync.fulfilled, (state, action) => {
            state.teacherName = action.payload.name;
            state.thunks.fetchTeacherNameLoading = false;
          })
          .addCase(fetchTeacherNameAsync.rejected, (state, action) => {
            state.thunks.fetchTeacherNameError = action.payload as string | null;
            state.thunks.fetchTeacherNameLoading = false;
          });
      }
});

export const {  } = firestoreSlice.actions;


export default firestoreSlice.reducer;

/*export const selectPlannedProgressForStudent = (state: RootState, student: StudentData) => {
  return state.firestore.progress.filter((progress: ProgressData) => progress.status === "Planned" && progress.studentRef === student.refPath);
};*/
