import React from "react";
import { CurriculumYear } from "../../types.ts";
import { useDispatch, useSelector } from "react-redux";

import "./SubjectTab.css";
import { RootState } from "../../store.ts";
import { toggleActiveYearFilter } from "../../slices/studentSlice.ts";

const CurriculumYearButtons: React.FC = () => {
  const dispatch = useDispatch();
  const curriculumYears: CurriculumYear[] = [
    CurriculumYear.First,
    CurriculumYear.Second,
    CurriculumYear.Third,
  ];
  const activeYear = useSelector(
    (state: RootState) => state.student.activeYearFilter
  );

  return curriculumYears.map((year) => {
    const activeCurriculumYear =
      activeYear === year ? "subject-tab-active" : "";
    return (
      <button
        key={year}
        className={`subject-tab ${activeCurriculumYear}`}
        onClick={() => dispatch(toggleActiveYearFilter(year))}
      >
        {year}
      </button>
    );
  });
};

export default CurriculumYearButtons;
