import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { createComment, updateComment } from "../../slices/progressSlice.ts";

interface DebouncedTextAreaProps {
  subject: string;
}

const DebouncedTextArea: React.FC<DebouncedTextAreaProps> = ({ subject }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const activeStudent = useSelector(
    (state: RootState) => state.student.activeStudentForStudentPage
  );

  const commentSections = useSelector(
    (state: RootState) => state.progress.commentSections
  );

  const CommentSection = commentSections.find(
    (section) =>
      section.studentRefPath === activeStudent?.refPath &&
      section.subject === subject
  );

  const [textValue, setTextValue] = useState<string>(
    CommentSection?.text || ""
  );

  const saveText = async (): Promise<void> => {
    setIsSaving(true);
    try {
      if (CommentSection) {
        console.log("sending an update");
        dispatch(
          updateComment({
            teacherUid: user.id as string,
            commentId: CommentSection?.id || "",
            updates: { text: textValueRef.current },
            olds: { text: textValue },
          })
        );
      } else {
        console.log("sending a create");
        dispatch(
          createComment({
            teacherUid: user.id as string,
            newComment: {
              text: textValueRef.current,
              subject: subject,
              studentRef: activeStudent?.refPath || "",
            },
          })
        );
      }
    } catch (error) {
      console.error("Failed to save text:", error);
      alert("Failed to save text.");
    } finally {
      setIsSaving(false);
    }
  };

  const debouncedSaveText = useCallback(debounce(saveText, 2000), []);

  useEffect(() => {
    return () => {
      debouncedSaveText.cancel();
    };
  }, [debouncedSaveText]);

  const textValueRef = useRef(textValue);
  useEffect(() => {
    textValueRef.current = textValue;
  }, [textValue]);

  // Event handlers
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
    debouncedSaveText();
  };

  const handleBlur = () => {
    debouncedSaveText.flush(); // Immediately invoke the debounced function
  };

  return (
    <div>
      <textarea
        value={textValue}
        className="textareaStyling"
        onChange={handleTextChange}
        onBlur={handleBlur}
        disabled={isSaving}
      />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default DebouncedTextArea;
