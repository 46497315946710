import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store'; // Adjust the import path as needed
import { ProgressData, StudentData } from '../types';

// Selector to get the list of students from the state
const selectStudents = (state: RootState) => state.firestore.students;

// Selector to get the active curriculum year from the state
const selectActiveCurriculumYear = (state: RootState) => state.student.activeYearFilter;

// Memoized selector to get filtered students based on the active curriculum year
export const selectFilteredStudents = createSelector(
  [selectStudents, selectActiveCurriculumYear],
  (students, year) => {
    if (!year) return students; // Return all students if no curriculum year is active
    return students.filter(student => student.yearInCurriculum === year);
  }
);


// Input selector to get the progress array from the state
const getProgress = (state: RootState) => state.progress.records;

// Input selector to pass the student object
// Note: This doesn't directly select from state, but prepares the student object for the output selector
const getStudent = (_: RootState, student: StudentData) => student;

const getActiveSubject = (state: RootState) => state.student.activeSubject;

// Memoized selector using createSelector
export const selectPlannedProgressForStudent = createSelector(
  [getProgress, getStudent, getActiveSubject, (state: RootState) => state.works.curriculumRefPaths],
  (progress, student, subject, curriculumRefPaths) => progress
  .filter((p: ProgressData) => p.status === "Planned" && p.studentRefPath === student.refPath && p.subject === subject)
  .sort((a, b) => curriculumRefPaths[subject].indexOf(a.workRefPath) - curriculumRefPaths[subject].indexOf(b.workRefPath))
  
);

export const selectPlannedWorksForStudent = createSelector(
    [
      (state: RootState, student: StudentData) => selectPlannedProgressForStudent(state, student),
      (state: RootState) => state.firestore.worksMap, 
    ],
    (plannedProgress, worksMap) => plannedProgress.map((progress) => worksMap[progress.workRefPath]?.name)
    
  );