import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  createNewWork,
  deleteWork,
  updateWorkName,
} from "../../slices/editModeSlice.ts";
import AddButton from "../AddButton.tsx";
import DeleteButton from "../DeleteButton.tsx";
import { useModal } from "../MyModal.tsx";

interface EditableWorkNameProps {
  workName: string;
  workRefPath: string;
  workIndex: number;
  indented?: boolean;
}

const EditableWorkName: React.FC<EditableWorkNameProps> = ({
  workName,
  workRefPath,
  workIndex,
  indented = false,
}) => {
  const editModeEnabled = useSelector(
    (state: RootState) => state.editMode.enabled
  );
  const teacherUid = useSelector((state: RootState) => state.user.user)
    ?.id as string;
  const subject = useSelector(
    (state: RootState) => state.student.activeSubject
  );
  const [newWorkName, setNewWorkName] = useState(workName); // Local state to track input changes

  const dispatch = useDispatch();
  const modal = useModal();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewWorkName(e.target.value);
  };

  const handleBlur = () => {
    if (newWorkName !== workName) {
      console.log("Work name has changed:", newWorkName, workRefPath);
      const payload = {
        newWorkName,
        oldWorkName: workName,
        workRefPath,
        teacherUid,
      };
      dispatch(updateWorkName(payload));
    } else {
      console.log("Work name has not changed.");
    }
  };

  useEffect(() => {
    setNewWorkName(workName);
  }, [workName]);

  const handleAddWorkBelow = () => {
    const payload = {
      workIndex: workIndex + 1,
      subject,
      teacherUid,
    };
    dispatch(createNewWork(payload));
  };

  const handleDeleteWork = async () => {
    const payload = {
      workIndex,
      subject,
      teacherUid,
    };
    const confirmed = await modal.openModal(
      `Delete ${workName} from the curriculum?`
    );
    if (!confirmed) {
      console.log("Cancelled!");
      return; // Exit the function if cancelled
    }
    console.log("Confirmed!");

    dispatch(deleteWork(payload));
  };

  return (
    <td className={`workName ${indented && "indentedWorkName"}`}>
      {editModeEnabled ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="text"
            value={newWorkName}
            style={{ width: "160px" }}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          <DeleteButton onClick={handleDeleteWork} />
          <AddButton onClick={handleAddWorkBelow} />
        </div>
      ) : (
        newWorkName
      )}
    </td>
  );
};

export default EditableWorkName;
