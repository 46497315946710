import { useState, useEffect } from "react";

export const sendUserToServer = async (user) => {
  const token = await user.getIdToken(); // Get the Firebase token

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/users`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Handle the response
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error("Error:", error);
  }
};

export const WIPModel = {
  _id: "",
  name: "",
  nameAbbreviated: "",
  works: "",
  students: "",
  student_order: "",
  work_orders_by_subject: "",
};

export const fetchClassIdFromTeacher = async (teacher_uid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/get_class_id/${teacher_uid}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching works in progress:", error);
    return []; // Return an empty array as a fallback
  }
};

export const fetchTeacherData = async (teacher_uid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/get_teacher_data/${teacher_uid}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching works in progress:", error);
    return []; // Return an empty array as a fallback
  }
};

export const fetchClassData = async (class_id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/classworks/${class_id}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const classData = {
      ...WIPModel,
      ...data,
    };
    return classData;
  } catch (error) {
    console.error("Error fetching works in progress:", error);
    return []; // Return an empty array as a fallback
  }
};

export const updateClassWorkStudentProgress = async (
  class_id,
  work_id,
  student_id,
  newStatus
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/classworks/${class_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          work_id: work_id,
          student_id: student_id,
          newStatus: newStatus,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating status:", error);
  }
};

export const updateClassWorkStudentProgressNotes = async (
  class_id,
  work_id,
  student_id,
  newNotes
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/classworks/notes/${class_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          work_id: work_id,
          student_id: student_id,
          newNotes: newNotes,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating status:", error);
  }
};

export const useFetchImages = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Assuming REACT_APP_API_SERVER_URL is the base URL of your server
    const url = `${process.env.REACT_APP_API_SERVER_URL}/images`; // Adjusted endpoint

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Assuming the data is an array of objects with 'key' and 'url'
        const imageData = data.map((item) => ({
          key: item.key, // S3 object key
          url: item.url, // Pre-signed URL for the image
        }));
        setImages(imageData);
      })
      .catch((error) => console.error("Error fetching images:", error));
  }, []); // Empty dependency array means this effect runs once after the initial render

  return images;
};

export const addDayLog = async (school_id, class_id, logDetails, date) => {
  console.log(school_id, class_id, logDetails, date); // logDetails should include time, teacher_id, work_id, student_id, from_progress, to_progress
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/daylog`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          school_id: school_id,
          class_id: class_id,
          details: logDetails, // Assuming logDetails is an object { time, teacher_id, work_id, student_id, from_progress, to_progress }
          date: date,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok for addDayLog");
    }

    const addedDayLog = await response.json();
    return addedDayLog;
  } catch (error) {
    console.error("Error adding day log:", error);
    throw error;
  }
};

export const updateStudentSubjectTextArea = async (
  student_id,
  subject,
  content
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVER_URL}/api/students/subjectTextArea/${student_id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: subject,
          content: content,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating student's subject text area:", error);
  }
};
