import { useSelector } from "react-redux";
import { Work } from "../../slices/worksSlice.js";
import { RootState } from "../../store.js";
import React, { useEffect, useRef } from "react";
import Sektor from "../../icons/sektor.js";
import { getColor67, getFullColor } from "../../utilities.js";
import { FullTriangle } from "../../icons/Triangles.js";

interface SummaryBeadProps {
  works: Work[];
  studentRefPath: string;
  toggle: (group: string) => void;
  groupName: string;
  withNoteIcon?: boolean;
}

const SektorComponent = ({
  size,
  stroke,
  angle,
  sectorColor,
  circleColor,
  fillCircle,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      size,
      stroke,
      angle,
      sectorColor,
      circleColor,
      fillCircle,
    };

    // Initialize Sektor instance when component mounts
    const sektor = new Sektor(containerRef.current, options);

    // Clean up when component unmounts
    return () => {
      // Any cleanup would go here
    };
  }, [size, stroke, angle, sectorColor, circleColor, fillCircle]);

  return <div ref={containerRef}></div>;
};

const SummaryBeadWithNoteCell: React.FC<SummaryBeadProps> = ({
  works,
  studentRefPath,
  toggle,
  groupName,
  withNoteIcon = false,
}) => {
  let MasteredCount = 0;
  const progress = useSelector((state: RootState) => state.progress.records);
  works.forEach((work) => {
    const singleProgress = progress.find((prog) => {
      return (
        prog.studentRefPath == studentRefPath &&
        prog.workRefPath == work.workRefPath
      );
    });
    if (singleProgress?.status === "Mastered") {
      MasteredCount += 1;
    }
  });
  const subject = useSelector(
    (state: RootState) => state.student.activeSubject
  );
  const activeStudentNotesID = useSelector(
    (state: RootState) => state.student.activeStudent
  );
  const color = getFullColor(subject);
  const degrees: number = Math.round((MasteredCount / works.length) * 360);
  const sector =
    degrees === 360 ? (
      <FullTriangle />
    ) : (
      <SektorComponent
        size={100}
        stroke={10}
        angle={degrees}
        sectorColor={color}
        circleColor="white"
        fillCircle={true}
      />
    );
  const NoProgress = degrees === 0;
  const beadBackgroundColor = degrees === 360 ? color : "white";
  const beadClassName = NoProgress ? "UnplannedBead" : "Bead";
  const toggleGroup = () => toggle(groupName);
  return (
    <>
      <td
        className="tdMinWidth"
        onClick={toggleGroup}
        style={{ cursor: "pointer" }}
      >
        <div className={`BeadContainer`}>
          <div
            className={beadClassName}
            style={{ backgroundColor: beadBackgroundColor }}
          >
            {!NoProgress && sector}
          </div>
        </div>
      </td>
      {studentRefPath === activeStudentNotesID?.refPath && (
        <td className="NoteCell"></td>
      )}
    </>
  );
};

export default SummaryBeadWithNoteCell;
