import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import "./SubjectTab.css";
import { setActiveSubject } from "../../slices/studentSlice.ts";

interface SubjectTabProps {
  subject: string;
}

const SubjectTab: React.FC<SubjectTabProps> = ({ subject }) => {
  const dispatch = useDispatch();
  const activeSubject = useSelector(
    (state: RootState) => state.student.activeSubject
  );

  const handleChangeActiveSubject = () => {
    dispatch(setActiveSubject(subject));
  };

  const activeSubjectClass =
    activeSubject === subject ? "subject-tab-active" : "";

  return (
    <button
      key={subject}
      onClick={() => handleChangeActiveSubject()}
      className={`subject-tab ${activeSubjectClass}`}
    >
      {subject}
    </button>
  );
};

export default SubjectTab;
