import React, { useState } from "react";
import "./LoginPage.css"; // Make sure to create this CSS file
import MontessoriClassroom from "../images/MontessoriClassroom.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, loginUserFailure } from "../slices/userSlice.ts";
import { UserModel } from "../types.ts";
import { auth } from "../firebase.ts";
import { RootState } from "../store.ts";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const message = useSelector((state: RootState) => state.user.message);

  auth.onAuthStateChanged(function (user) {
    if (user && user.email) {
      console.log("user is signed in");
      const usermodel: UserModel = {
        id: user.uid,
        email: user.email,
        displayName:
          user.email.split("@")[0][0].toUpperCase() +
          user?.email.split("@")[0].substring(1),
      };
      dispatch(setUser(usermodel));
      navigate("/");
    } else {
      console.log("no user in signed in");
    }
  });

  const onSignUp = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user.email == null) {
          throw new Error("email shouldn't be null");
        }
        const usermodel: UserModel = {
          id: user.uid,
          email: user.email,
          displayName:
            user.email.split("@")[0][0].toUpperCase() +
            user?.email.split("@")[0].substring(1),
        };
        dispatch(setUser(usermodel));
        //sendUserToServer(user);
      })
      .catch((error) => {
        dispatch(loginUserFailure(error.message));
      });
  };

  return (
    <div className="login-container">
      <div className="login-image">
        <img src={MontessoriClassroom} alt="Login" />
      </div>
      <div className="login-form-container">
        <div className="login-form">
          <h1>Login</h1>
          <form>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button onClick={onSignUp}>Submit</button>
            {message && <div>{message}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
