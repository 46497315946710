import React, { useState } from "react";
import { StudentData } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { useClickAndHold } from "../../hooks/useClickAndHold.tsx";
import { useNavigate } from "react-router-dom";
import NotesColumnHeader from "./NotesColumnHeader.tsx";
import {
  toggleActiveStudent,
  setActiveStudent,
} from "../../slices/studentSlice.ts";
import PlannedWorksPopup from "./PlannedWorksPopup.tsx";

interface WorkRopeTableStudentThProps {
  student: StudentData;
}

const WorkRopeTableStudentTh: React.FC<WorkRopeTableStudentThProps> = ({
  student,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);

  const sendToStudentPage = () => {
    dispatch(setActiveStudent(student));
    navigate("/student");
  };

  const openOrCloseStudentNotes = () => {
    dispatch(toggleActiveStudent(student));
  };

  const { bind, handleClickIntermediary } = useClickAndHold({
    onLongPress: () => setIsShown(true),
    onClick: openOrCloseStudentNotes,
  });

  return (
    <>
      <th
        className="studentTh"
        onClick={handleClickIntermediary}
        onDoubleClick={sendToStudentPage}
        {...bind()}
        onMouseLeave={() => setIsShown(false)}
      >
        {isShown && <PlannedWorksPopup student={student} />}
        {student.name}
      </th>
      <NotesColumnHeader student={student} />
    </>
  );
};

export default WorkRopeTableStudentTh;
