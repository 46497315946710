import React from "react";
import ExpandButton from "../ExpandButton.tsx";
import CollapseButton from "../CollapseButton.tsx";

interface WorkGroupNameProps {
  name: string;
  visible: boolean;
  toggle: (group: string) => void;
}

const WorkGroupName: React.FC<WorkGroupNameProps> = ({
  name,
  toggle,
  visible,
}) => {
  const toggleGroup = () => toggle(name);
  return (
    <td className="workName">
      <span>{name}</span>
      {visible ? (
        <CollapseButton onClick={toggleGroup} />
      ) : (
        <ExpandButton onClick={toggleGroup} />
      )}
    </td>
  );
};

export default WorkGroupName;
