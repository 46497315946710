import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { toggleEditMode } from "../../slices/editModeSlice.ts";
import React from "react";

const EditModeButton = () => {
  const editModeEnabled = useSelector((state: RootState) => state.editMode.enabled);
  const dispatch = useDispatch();

  return (
    <button
      onClick={() => {
        dispatch(toggleEditMode());
      }}
      style={{ backgroundColor: editModeEnabled ? "blue" : "gray" }}
    >
      Edit Mode
    </button>
  );
};

export default EditModeButton;
