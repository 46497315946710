import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CurriculumYear, StudentData } from "../types";

interface StudentState {
    notesShown: boolean,
    activeStudent: StudentData | null,
    activeStudentForStudentPage: StudentData | null,
    activeSubject: string,
    activeYearFilter: CurriculumYear | null,
}

const initialState: StudentState = {
    notesShown: false,
    activeStudent: null,
    activeStudentForStudentPage: null,
    activeSubject: "Practical Life",
    activeYearFilter: null,
}

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: { 
        toggleNotesShown: (state) => { 
            state.notesShown = !state.notesShown;
        },
        toggleActiveStudent: (state, action: PayloadAction<StudentState['activeStudent']>) => {
            if (state.activeStudent?.refPath == action.payload?.refPath) {
                state.activeStudent = null;
            } else {
                state.activeStudent = action.payload;
                state.activeStudentForStudentPage = action.payload;
            }
        },
        setActiveStudent: (state, action: PayloadAction<StudentState['activeStudent']>) => {
            state.activeStudent = action.payload;
            state.activeStudentForStudentPage = action.payload;
        },
        setActiveSubject: (state, action: PayloadAction<StudentState['activeSubject']>) => {
            state.activeSubject = action.payload;
        },
        toggleActiveYearFilter: (state, action: PayloadAction<StudentState['activeYearFilter']>) => {
            if (state.activeYearFilter == action.payload) {
                state.activeYearFilter = null;
            } else {
                state.activeYearFilter = action.payload;
            }
        },
    } 
})

export const { toggleNotesShown, toggleActiveStudent, setActiveStudent, setActiveSubject, toggleActiveYearFilter } = studentSlice.actions;
export default studentSlice.reducer;