import { Middleware } from "redux";
import { createNewWork, deleteWork, updateWorkName } from "../slices/editModeSlice.ts";
import { addDoc, collection, doc, getDoc, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase.ts';
import { School } from "../types.ts";

export const editModeMiddleware: Middleware = (store) => (next) => async (action) => {
    next(action);

    if (updateWorkName.match(action)) {
        const { newWorkName, oldWorkName, workRefPath, teacherUid } = action.payload;
        const teacherDocRef = doc(db, 'Users', teacherUid);
        const teacherDoc = await getDoc(teacherDocRef);
        if (teacherDoc.exists() && teacherDoc.data().class) {
            // Parse the work document reference from the provided path
            const classRef = teacherDoc.data().class;
            const workDocRef = doc(db, workRefPath);

            // Current date and time for logging
            const currentDateTime = new Date();
            const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
            const monthYearDocRef = doc(classRef, "Logs", monthYear);
            const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
            const logData = {
                workRef: workDocRef,
                old: {name: oldWorkName},
                new: {name: newWorkName},
                date: currentDateTime.toLocaleDateString(),
                time: currentDateTime.toLocaleTimeString(),
                action: "renameWork",
                teacherRef: teacherDocRef
            };

            try {
                // Update the workName in Firestore
                await updateDoc(workDocRef, { name: newWorkName });
                
                // Log the change
                await addDoc(logsSubcollectionRef, logData);
                
                console.log('Work name updated successfully from', oldWorkName, 'to', newWorkName);
            } catch (error) {
                console.error('Error updating work name:', error);
                // Optionally, you could dispatch an error action here
            }
        }
    } else if (createNewWork.match(action)) {
        const { workIndex, subject, teacherUid } = action.payload;
        const teacherDocRef = doc(db, 'Users', teacherUid);
        const teacherDoc = await getDoc(teacherDocRef);
    
        if (teacherDoc.exists() && teacherDoc.data().class) {
          const classRef = teacherDoc.data().class;
          const schoolRef = teacherDoc.data().school;
          try {
          await runTransaction(db, async (transaction) => {
            const schoolDoc = await transaction.get(schoolRef);
            if (!schoolDoc.exists) {
              throw new Error("School document does not exist!");
            }

            const newWorkRef = doc(collection(schoolRef, "Works"));
            const newWorkData = {
                name: "New Work",
                subject: subject,
              };
    
            // Create the new work entry in Firestore within the transaction
            transaction.set(newWorkRef, newWorkData);

            // Update the curriculum array
            const schoolData: School = schoolDoc.data() as School;
            const curriculum = schoolData.curriculum || {};
            curriculum[subject] = curriculum[subject] || [];
            curriculum[subject].splice(workIndex, 0, newWorkRef);

            // Update the document with the new curriculum array
            transaction.update(schoolRef, { curriculum });

            const currentDateTime = new Date();
            const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
            const monthYearDocRef = doc(classRef, "Logs", monthYear);
            const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
            const logData = {
                workRef: newWorkRef,
                subject: subject,
                index: workIndex,
                date: currentDateTime.toLocaleDateString(),
                time: currentDateTime.toLocaleTimeString(),
                action: "createNewWork",
                teacherRef: teacherDocRef
            };

            transaction.set(doc(logsSubcollectionRef), logData); 
          });
          console.log('New work created and logged successfully:', subject);
        } catch (error) {
            console.error('Error creating new work and logging:', error);
            // Optionally, you could dispatch an error action here
        }
      }
    } else if (deleteWork.match(action)) {
        const { workIndex, subject, teacherUid } = action.payload;
        const teacherDocRef = doc(db, 'Users', teacherUid);
        const teacherDoc = await getDoc(teacherDocRef);
        if (teacherDoc.exists() && teacherDoc.data().class) {
            const classRef = teacherDoc.data().class;
            const schoolRef = teacherDoc.data().school;
            try {
                await runTransaction(db, async (transaction) => {
                    const schoolDoc = await transaction.get(schoolRef);
                    if (!schoolDoc.exists()) {
                        throw new Error("School document does not exist!");
                    }
    
                    const schoolData: School = schoolDoc.data() as School;
                    if (!schoolData.curriculum || !schoolData.curriculum[subject]) {
                        throw new Error("Subject not found in curriculum!");
                    }

                    // Remove the specified work from the curriculum
                    const curriculum = schoolData.curriculum;
                    const workRemoved = curriculum[subject].splice(workIndex, 1)[0];

                    // Update the school document with the modified curriculum
                    transaction.update(schoolRef, { curriculum });

                    // Log the deletion
                    const currentDateTime = new Date();
                    const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
                    const monthYearDocRef = doc(classRef, "Logs", monthYear);
                    const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
                    const logData = {
                        subject: subject,
                        index: workIndex,
                        workRef: workRemoved,
                        date: currentDateTime.toLocaleDateString(),
                        time: currentDateTime.toLocaleTimeString(),
                        action: "deleteWork",
                        teacherRef: teacherDocRef
                    };

                    transaction.set(doc(logsSubcollectionRef), logData);
                });
            } catch (error) {
                console.error('Error deleting work and logging:', error);
                // Optionally, you could dispatch an error action here
            }
        }
    }
};  