const getPastelColor = (subject) => {
    const colorMap = {
        "Language": "#ffd1e0", // even lighter pastel red
        "Sensorial": "#ffffcc", // even lighter pastel yellow
        "Math": "#d1fde1", // even lighter pastel green
        "Geography": "#e6d0ff", // even lighter pastel purple
        "Science": "#ffe6d1", // even lighter pastel orange
        "Practical Life": "#b3e6ff" // even lighter pastel blue
    };

    return colorMap[subject] || '#FFF'; // Replace 'defaultColor' with a color of your choice for undefined subjects
};

export const getBlankColor = (subject) => {
    return '#FFF';
}

export default getPastelColor;

export const getFullColor = (subject) => {
    const colorMap = {
        "Language": "#ff69b4", // bright pink (similar to a deeper pastel red)
        "Sensorial": "#ffff00", // bright yellow
        "Math": "#32cd32", // lime green
        "Geography": "#800080", // purple
        "Science": "#ff8c00", // dark orange
        "Practical Life": "#00bfff" // deep sky blue
    };

    return colorMap[subject] || '#777777'; // A default color for undefined subjects
};

export const getColor33 = (subject) => {
    const colorMap = {
        "Language": "#ffadd6", // Lighter pink
        "Sensorial": "#ffff80", // Lighter yellow
        "Math": "#96f0a1", // Lighter green
        "Geography": "#b99aff", // Lighter purple
        "Science": "#ffcc99", // Lighter orange
        "Practical Life": "#80d4ff" // Lighter blue
    };

    return colorMap[subject] || '#777777';
};

export const getColor67 = (subject) => {
    const colorMap = {
        "Language": "#ff7ebf", // Medium pink
        "Sensorial": "#ffff40", // Medium yellow
        "Math": "#61e06b", // Medium green
        "Geography": "#9933ff", // Medium purple
        "Science": "#ff9933", // Medium orange
        "Practical Life": "#40a4ff" // Medium blue
    };

    return colorMap[subject] || '#777777';
};