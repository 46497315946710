import React from "react";
import NewWorkRopeBead from "../../components/NewWorkRopeBead.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface NewStudentPageCellProps {
  workName: string;
  workNotes: string;
  status: string;
  subject: string;
  progressRefPath: string;
  workRefPath: string;
  studentRefPath: string;
}

export const NewStudentPageCell: React.FC<NewStudentPageCellProps> = ({
  workName,
  workNotes,
  status,
  subject,
  progressRefPath,
  workRefPath,
  studentRefPath,
}) => {
  const notesShown = useSelector(
    (state: RootState) => state.student.notesShown
  );
  const activeStudent = useSelector(
    (state: RootState) => state.student.activeStudentForStudentPage
  );

  return (
    <React.Fragment key={progressRefPath}>
      <NewWorkRopeBead
        status={status}
        subject={subject}
        progressRefPath={progressRefPath}
        workRefPath={workRefPath}
        studentRefPath={studentRefPath}
        small={true}
        workName={workName}
        studentName={activeStudent?.name || ""}
      />
      <td key={workName}>
        <div
          style={{
            textOverflow: "ellipsis",
            maxWidth: "200px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {workName}
        </div>
        {notesShown && workNotes && (
          <>
            <span
              style={{ color: "gray", marginLeft: "10px", marginTop: "20px" }}
            >
              {workNotes}
            </span>
          </>
        )}
      </td>
    </React.Fragment>
  );
};
