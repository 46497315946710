import { Middleware } from "redux";
import { setCurriculum, setCurriculumOrder, setCurriculumRefPaths, triggerFetchWorks, stopFetchWorks, setCurriculumGroups, Work, WorkGroup } from "../slices/worksSlice.ts";
import { doc, getDoc, collection, query, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "../firebase.ts";

let unsubscribe: (() => void) | null = null;

export const worksMiddleware: Middleware = store => next => async action => {
    next(action);

    if (triggerFetchWorks.match(action)) {
        const teacherUid = action.payload;
        const teacherDocRef = doc(db, 'Users', teacherUid);

        try {
            const teacherDoc = await getDoc(teacherDocRef);

            if (!teacherDoc.exists()) {
                console.log("Teacher not found.");
                return;
            }

            const schoolRef = teacherDoc.data().school;
            if (!schoolRef) {
                console.log("School reference not found in teacher's document.");
                return;
            }
            unsubscribe = onSnapshot(schoolRef, async (schoolDocSnapshot) => {
                if (!schoolDocSnapshot.exists()) {
                    console.log("School document not found.");
                    return;
                }

                const data = schoolDocSnapshot.data();
                const curriculumOrder = data.curriculumOrder || [];
                store.dispatch(setCurriculumOrder(curriculumOrder));

                const worksRef = collection(schoolRef, "Works");
                const worksSnapshot = await getDocs(worksRef);

                if (worksSnapshot.empty) {
                    console.log("No works found for the school.");
                    return;
                }

                const worksMap = {};
                worksSnapshot.docs.forEach(doc => {
                    worksMap[doc.ref.path] = {
                        name: doc.data().name,
                        subject: doc.data().subject,
                    };
                });

                const workGroupsRef = collection(schoolRef, "WorkGroups");
                const workGroupsSnapshot = await getDocs(workGroupsRef);

                const curriculumGroups: { [key: string]: WorkGroup[] } = {};
                if (workGroupsSnapshot.empty) {
                    console.log("No works group found for the school.");
                } else {  
                    workGroupsSnapshot.docs.forEach(doc => {
                        curriculumGroups[doc.data().subject] = curriculumGroups[doc.data().subject] || [];
                        const workArray = doc.data().works.map((workRef) => ({workName: worksMap[workRef.path].name, workRefPath: workRef.path}))

                        // Push the new element into the array
                        curriculumGroups[doc.data().subject].push({
                            groupRefPath: doc.ref.path,
                            groupName: doc.data().name,
                            visible: false,
                            works: workArray,
                        });             
                    });
                }

                const curriculum = {};
                const curriculumRefPaths = {};
                Object.keys(data.curriculum).forEach(subject => {
                    curriculum[subject] = data.curriculum[subject]
                        .map(ref => worksMap[ref.path] ? worksMap[ref.path].name : undefined)
                        .filter(name => name !== undefined);
                    curriculumRefPaths[subject] = data.curriculum[subject].map(ref => ref.path);
                });

                store.dispatch(setCurriculum(curriculum));
                store.dispatch(setCurriculumRefPaths(curriculumRefPaths));
                store.dispatch(setCurriculumGroups(curriculumGroups));
            });
        } catch (error) {
            console.error("Error retrieving works:", error);
        }
    } else if (stopFetchWorks.match(action)) {
        if (unsubscribe) {
            unsubscribe();
            unsubscribe = null;
        }
    }
};
