import { useState } from "react"
import { useLongPress } from "use-long-press";

export const useClickAndHold = ({onLongPress, onClick}) => {

    const [activeClick, setActiveClick] = useState(false);
    const [longPress, setLongPress] = useState(false);

    const bind = useLongPress(onLongPress, {
        threshold: 500,
        onStart: (event) => setActiveClick(true),
        onFinish: (event) => {
            setActiveClick(false);
            setLongPress(false);
        },
        onCancel: (event) => { },
        captureEvent: true,
    });

    const handleClickIntermediary = () => {
        if (activeClick && !longPress) {
            onClick();
            setActiveClick(false);
        } else {
            setLongPress(false);
        }
    }

    return { bind, handleClickIntermediary }
}