import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { stopFetchWorks, triggerFetchWorks } from "../slices/worksSlice.ts";

export const useWorks = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    dispatch(triggerFetchWorks(user.id as string));
    return () => {
      dispatch(stopFetchWorks());
    };
  }, [user, dispatch]);
};
