import React from "react";

const ExpandButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        background: "none",
        border: "none",
        cursor: "pointer",
        padding: "10px",
      }}
    >
      <svg
        fill="#000000"
        width="15px"
        height="15px"
        viewBox="0 0 1920 1920"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M960.182.012 451 509.193l82.7 82.817 368.112-368.113v1472.217L533.7 1328.12l-82.7 82.7L960.182 1920l509.181-509.182-82.582-82.7-368.113 367.996V223.897l368.113 368.113 82.582-82.817z"
          fill-rule="evenodd"
        />
      </svg>
    </button>
  );
};

export default ExpandButton;
