import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SubjectCommentSection } from "./SubjectCommentSection.tsx";
import { NewStudentPageCell } from "./NewStudentPageCell.tsx";

interface StudentPageRowsProps {}

export const StudentPageRows: React.FC<StudentPageRowsProps> = ({}) => {
  let rows: React.ReactNode[] = [];

  const activeStudent = useSelector(
    (state: RootState) => state.student.activeStudentForStudentPage
  );
  const curriculum = useSelector(
    (state: RootState) => state.works.curriculum
  );
  const curriculumRefPaths = useSelector(
    (state: RootState) => state.works.curriculumRefPaths
  );
  const progress = useSelector((state: RootState) => state.progress.records);
  const subjects = useSelector(
    (state: RootState) => state.works.curriculumOrder
  );

  const rowsForTextArea = 4;

  const amountOfWorksPerSubject = subjects.map(
    (subject) => curriculum[subject].length
  );
  const amountOfWorksInLongestSubject = Math.max(...amountOfWorksPerSubject);

  for (
    let index = 0;
    index < amountOfWorksInLongestSubject + rowsForTextArea;
    index++
  ) {
    rows.push(
      <tr key={index}>
        {subjects.map((subject: string, subject_index: number) => {
          const workToDisplay = curriculum[subject][index] || undefined;
          if (!workToDisplay) {
            const howFarPast = index - amountOfWorksPerSubject[subject_index];

            if (howFarPast > rowsForTextArea) {
              return (
                <React.Fragment key={`work-${index}-${subject_index}`}>
                  <td key={1}></td>
                  <td key={2}></td>
                </React.Fragment>
              );
            }
            if (howFarPast > 0) {
              return <React.Fragment key={`work-${index}-${subject_index}`}></React.Fragment>;
            }
            return (
              <SubjectCommentSection
                rowsForTextArea={rowsForTextArea}
                subject={subject}
                subject_index={subject_index}
                index={howFarPast}
              />
            );
          }

          const singleProgress = progress.find(
            (prog) =>
              prog.studentRefPath == activeStudent?.refPath &&
              prog.workRefPath == curriculumRefPaths[subject][index]
          );

          return (
            <NewStudentPageCell
              workName={workToDisplay}
              workNotes={singleProgress?.notes || ""}
              status={singleProgress?.status || "Unplanned"}
              subject={subject}
              progressRefPath={singleProgress?.id || ""}
              workRefPath={curriculumRefPaths[subject][index]}
              studentRefPath={activeStudent?.refPath || ""}
            />
          );
        })}
      </tr>
    );
  }

  return <>{rows}</>;
};
