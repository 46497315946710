// src/App.js
import React from "react";
import { Route, Routes } from "react-router-dom";

import SidebarLayout from "./components/SidebarLayout.tsx";
import LoginPage from "./pages/LoginPage.tsx";
import SnapshotView from "./pages/SnapshotView.tsx";
import ResourcesPage from "./pages/ResourcesPage.tsx";

import "./App.css";
import StudentPage from "./pages/StudentPage/StudentPage.tsx";

function App() {
  return (
    <div className="app-container">
      <div className="content">
        <Routes>
          <Route element={<SidebarLayout />}>
            <Route index path="/" element={<SnapshotView />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/student" element={<StudentPage />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
