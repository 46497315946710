import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import userReducer from './slices/userSlice.ts';
import classroomReducer from './slices/classroomSlice.ts';
import studentReducer from './slices/studentSlice.ts';
import firestoreReducer from './slices/firestoreSlice.ts'
import firestoreReducerTwo from './slices/firestoreSliceTwo.ts'
import editModeReducer from './slices/editModeSlice.ts'
import { firestoreSyncMiddleware } from './realtime/firestoreMiddleware.ts';
import progressReducer from './slices/progressSlice.ts';
import worksReducer from './slices/worksSlice.ts'
import settingsReducer from './slices/settingsSlice.ts'
import { progressMiddleware } from './realtime/progressMiddleware.ts';
import { editModeMiddleware } from './realtime/editModeMiddleware.ts';
import { worksMiddleware } from './realtime/worksMiddleware.ts';
import { settingsMiddleware } from './realtime/settingsMiddleware.ts';


const rootReducer = combineReducers({
  user: userReducer,
  classroom: classroomReducer,
  student: studentReducer,
  firestore: firestoreReducer,
  firestoreTwo: firestoreReducerTwo,
  progress: progressReducer,
  editMode: editModeReducer,
  works: worksReducer,
  settings: settingsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // Here you can specify which parts of the state you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(firestoreSyncMiddleware).concat(progressMiddleware).concat(editModeMiddleware).concat(worksMiddleware).concat(settingsMiddleware),
});

export const persistor = persistStore(store);

// Define RootState type based on the state structure
export type RootState = ReturnType<typeof store.getState>;

// If you need to get the AppDispatch type, you can define it as well
export type AppDispatch = typeof store.dispatch;