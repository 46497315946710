export const FullTriangle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" className="triangle-svg">
    <line x1="2" y1="16" x2="10" y2="2" stroke="#000" strokeWidth="1" />
    <line x1="10" y1="2" x2="18" y2="16" stroke="#000" strokeWidth="1" />
    <line
      x1="18"
      y1="16"
      x2="2"
      y2="16"
      stroke="#000"
      strokeWidth="1"
      fill="none"
    />
  </svg>
);

export const TwoPartsOfTriangleLR = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" className="triangle-svg">
    <line x1="2" y1="16" x2="10" y2="2" stroke="#000" strokeWidth="1" />
    <line x1="10" y1="2" x2="18" y2="16" stroke="#000" strokeWidth="1" />
  </svg>
);

export const TwoPartsOfTriangleLB = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" className="triangle-svg">
    <line x1="2" y1="16" x2="10" y2="2" stroke="#000" strokeWidth="1" />
    <line
      x1="18"
      y1="16"
      x2="2"
      y2="16"
      stroke="#000"
      strokeWidth="1"
      fill="none"
    />
  </svg>
);

export const OnePartOfTriangle = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" className="triangle-svg">
    <line x1="2" y1="16" x2="10" y2="2" stroke="#000" strokeWidth="1" />
  </svg>
);

export const Dud = () => <div />;
