import React, { useCallback, useEffect, useState } from 'react';
import { useFetchImages } from '../oldApi'; // Adjust the import path as necessary

interface Image {
  key: string;
  url: string;
}

const ResourcesPage: React.FC = () => {
    const images: Image[] = useFetchImages();
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const showNext = useCallback(() => {
        setCurrentIndex(prevIndex => (prevIndex !== null ? (prevIndex + 1) % images.length : null));
    }, [images.length]);

    const showPrev = useCallback(() => {
        setCurrentIndex(prevIndex => (prevIndex !== null ? (prevIndex - 1 + images.length) % images.length : null));
    }, [images.length]);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.code === 'ArrowRight') {
            showNext();
        } else if (e.code === 'ArrowLeft') {
            showPrev();
        }
    }, [showNext, showPrev]);

    useEffect(() => {
        if (currentIndex !== null) {
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, handleKeyDown]);

    const openModal = (index: number) => {
        setCurrentIndex(index);
    };

    const closeModal = () => {
        setCurrentIndex(null);
    };

    return (
        <>
            <div className="gallery">
                {images.map((image, index) => (
                    <img
                        key={image.key}
                        src={image.url}
                        alt="Gallery Item"
                        onClick={() => openModal(index)}
                        style={{ width: '150px', height: '200px', margin: '7px', cursor: 'pointer' }}
                    />
                ))}
            </div>
            {currentIndex !== null && (
                <div 
                    className="modal" 
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={closeModal}
                >
                    <button onClick={(e) => { e.stopPropagation(); showPrev(); }}>&lt;</button>
                    <img 
                        src={images[currentIndex].url} 
                        alt="Expanded" 
                        style={{
                            maxWidth: '80%',
                            maxHeight: '80%',
                            margin: '0 20px',
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking on the image
                    />
                    <button onClick={(e) => { e.stopPropagation(); showNext(); }}>&gt;</button>
                </div>
            )}
        </>
    );
}

export default ResourcesPage;
