// src/middleware/progressMiddleware.ts
import { Middleware } from '@reduxjs/toolkit';
import { db } from '../firebase.ts';
import { doc, collection, onSnapshot, getDoc, query, updateDoc, addDoc } from 'firebase/firestore';
import { setProgressRecords, triggerFetchProgress, stopFetchProgress, updateProgressRecord, createProgressRecord, triggerFetchComments, updateComment, createComment, stopFetchComments, setComments } from '../slices/progressSlice.ts';
import { CommentSectionData, ProgressData } from '../types';

let unsubscribe: (() => void) | null = null;
let unsubscribeComments: (() => void) | null = null;

export const progressMiddleware: Middleware = (store) => (next) => async (action) => {
  next(action);

  if (triggerFetchProgress.match(action)) {
    const teacherUid = action.payload;
    // Assuming the teacher's document contains a reference to the class
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);

    if (teacherDoc.exists() && teacherDoc.data().class) {
        // Access the Classroom document to reach the Progress sub-collection
        const classRef = teacherDoc.data().class;
        const progressRef = collection(classRef, "Progress");

        unsubscribe = onSnapshot(query(progressRef), (querySnapshot) => {
        const progressRecords = querySnapshot.docs.map((doc) => { 
            //console.log(doc.data(), doc.id);
            return (
            {
            ...doc.data() as ProgressData,
            id: doc.id,
            studentRefPath: doc.data().studentRef.path,
            workRefPath: doc.data().workRef.path,
            studentRef: null,
            workRef: null,
        })});
        store.dispatch(setProgressRecords(progressRecords));
        });
    }
  } else if (stopFetchProgress.match(action)) {
    // Unsubscribe from the Firestore updates
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }
  } else if (updateProgressRecord.match(action)) {
    // Handle progress record update
    const { teacherUid, progressId, updates, olds } = action.payload;
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);
    if (teacherDoc.exists() && teacherDoc.data().class) {
        const classRef = teacherDoc.data().class;
        const progressRef = collection(classRef, "Progress");
        const progressDocRef = doc(progressRef, `${progressId}`);
        
        const currentDateTime = new Date();
        const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthYearDocRef = doc(classRef, "Logs", monthYear);
        const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
        const logData = {progressRef: progressDocRef, teacherRef: teacherDocRef, old: olds, new: updates, date: currentDateTime.toLocaleDateString(), time: currentDateTime.toLocaleTimeString(), action: "progressUpdate"}
        
        const { id: _, ...newUpdates } = updates;

        try {
          await updateDoc(progressDocRef, newUpdates);
          await addDoc(logsSubcollectionRef, logData);
          console.log('Progress record updated successfully');
        } catch (error) {
          console.error('Error updating progress record:', error);
        // Dispatch an error action or handle accordingly
        }
    }
  } else if (createProgressRecord.match(action)) {
    // Handle new progress record creation
    const { teacherUid, newProgress } = action.payload;
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);
    if (teacherDoc.exists() && teacherDoc.data().class) {
        const classRef = teacherDoc.data().class;
        const progressRef = collection(classRef, "Progress");
        const { id: _, studentRefPath: __, workRefPath: ____, ...newProgressData } = newProgress;
        const newProgressDataWRefs = {...newProgressData, studentRef: doc(db, newProgress.studentRefPath), workRef: doc(db, newProgress.workRefPath)}

        const currentDateTime = new Date();
        const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthYearDocRef = doc(classRef, "Logs", monthYear);
        const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
        
        try {
            const newProgressDocRef = await addDoc(progressRef, newProgressDataWRefs);
            const logData = {progressRef: newProgressDocRef, teacherRef: teacherDocRef, new: newProgressDataWRefs, date: currentDateTime.toLocaleDateString(), time: currentDateTime.toLocaleTimeString(), action: "progressUpdate"}
            await addDoc(logsSubcollectionRef, logData);
            console.log('New progress record created successfully');
        } catch (error) {
            console.error('Error creating new progress record:', error);
            // Dispatch an error action or handle accordingly
        }
    }
  } else if (triggerFetchComments.match(action)) {
    const teacherUid = action.payload;
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);
  
    if (teacherDoc.exists() && teacherDoc.data().class) {
      const classRef = teacherDoc.data().class;
      const commentsRef = collection(classRef, "CommentSections");
      unsubscribeComments = onSnapshot(query(commentsRef), (querySnapshot) => {
        const comments = querySnapshot.docs.map(doc => ({
          ...doc.data() as CommentSectionData,
          id: doc.id,
          studentRefPath: doc.data().studentRef.path,
          studentRef: null
        }));
        // Assuming you have an action to set comments in your state
        store.dispatch(setComments(comments)); 
      });
    }
  } else if (stopFetchComments.match(action)) {
    // Check if there's an active subscription to unsubscribe from
    if (unsubscribeComments) {
        unsubscribeComments(); // Call the unsubscribe function to stop listening to Firestore updates
        unsubscribeComments = null; // Reset the unsubscribe function to null
      console.log('Unsubscribed from CommentSections updates');
    }
  } else if (updateComment.match(action)) {
    const { teacherUid, commentId, updates, olds } = action.payload;
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);
  
    if (teacherDoc.exists() && teacherDoc.data().class) {
        const classRef = teacherDoc.data().class;
        const commentsRef = collection(classRef, "CommentSections");
        const commentDocRef = doc(commentsRef, commentId);

        const currentDateTime = new Date();
        const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthYearDocRef = doc(classRef, "Logs", monthYear);
        const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
        const logData = {commentRef: commentDocRef, teacherRef: teacherDocRef, old: olds, new: updates, date: currentDateTime.toLocaleDateString(), time: currentDateTime.toLocaleTimeString(), action: "commentSectionUpdate"}
        try {
            await updateDoc(commentDocRef, updates);
            await addDoc(logsSubcollectionRef, logData);
            console.log('Comment updated successfully', commentDocRef, updates);
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    }
  } else if (createComment.match(action)) {
    const { teacherUid, newComment } = action.payload;
    const teacherDocRef = doc(db, 'Users', teacherUid);
    const teacherDoc = await getDoc(teacherDocRef);
  
    if (teacherDoc.exists() && teacherDoc.data().class) {
        const classRef = teacherDoc.data().class;
        const commentsRef = collection(classRef, "CommentSections");

        const currentDateTime = new Date();
        const monthYear = currentDateTime.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthYearDocRef = doc(classRef, "Logs", monthYear);
        const logsSubcollectionRef = collection(monthYearDocRef, "Entries");
        

        try {
            const newCommentWRef = {...newComment, studentRef: doc(db, newComment.studentRef)}
            const commentDocRef = await addDoc(commentsRef, newCommentWRef);
            const logData = {commentRef: commentDocRef, teacherRef: teacherDocRef, new: newCommentWRef, date: currentDateTime.toLocaleDateString(), time: currentDateTime.toLocaleTimeString(), action: "commentSectionUpdate"}
            await addDoc(logsSubcollectionRef, logData);
            console.log('New comment created successfully');
        } catch (error) {
            console.error('Error creating new comment:', error);
        }
    }
  }
};
