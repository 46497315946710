import React from "react";

const CollapseButton = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      style={{
        background: "none",
        border: "none",
        cursor: "pointer",
        padding: "10px",
      }}
    >
      <svg
        width="15px"
        height="15px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M7.56994 5.00001L6.50928 6.06067L11.9999 11.5536L17.4906 6.06067L16.43 5.00001L11.9999 9.43186L7.56994 5.00001Z"
            fill="#1F2328"
          ></path>{" "}
          <path
            d="M16.4298 19L17.4905 17.9394L11.9999 12.4465L6.5094 17.9394L7.57006 19L11.9999 14.5683L16.4298 19Z"
            fill="#1F2328"
          ></path>{" "}
        </g>
      </svg>
    </span>
  );
};

export default CollapseButton;
