import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import {
  fetchProgressBySubjectAsync,
  fetchStudentsForTeacherAsync,
  fetchTeacherNameAsync,
  fetchWorksForSchoolAsync,
} from "../slices/firestoreSlice.ts";
import {
  triggerTeacherNameSubscription,
  stopTeacherNameSubscription,
} from "../slices/firestoreSliceTwo.ts";

export const useFirestoreDb = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (user?.id) {
      // Dispatch the action to trigger Firestore subscription middleware
      dispatch(triggerTeacherNameSubscription(user.id));
    }
    return () => {
      // Dispatch action to stop listening when the component unmounts
      dispatch(stopTeacherNameSubscription());
    };
  }, [user, dispatch]);

  useEffect(() => {
    const setupData = async () => {
      dispatch(fetchStudentsForTeacherAsync(user.id as string));
      //dispatch(fetchWorksForSchoolAsync(user.id as string));
    };
    setupData();
  }, [user]);
};
