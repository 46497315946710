import React, { ReactNode, createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";

// Define the context type with a promise-based openModal
type ModalContextType = {
  openModal: (message: string) => Promise<boolean>;
};

const ModalContext = createContext<ModalContextType>({
  openModal: () => Promise.reject("Modal context is not set"), // Placeholder rejection
});

// ModalProvider manages modal visibility and resolves the promise based on user interaction
export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [resolver, setResolver] = useState<((value: boolean) => void) | null>(
    null
  );

  const openModal = (message: string): Promise<boolean> => {
    setIsOpen(true);
    setMessage(message);
    return new Promise<boolean>((resolve) => {
      setResolver(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (resolver) resolver(true);
    close();
  };

  const handleCancel = () => {
    if (resolver) resolver(false);
    close();
  };

  const close = () => {
    setIsOpen(false);
    setResolver(null);
    setMessage("");
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      {isOpen && (
        <MyModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          message={message}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

// Modal component structure
const MyModal = ({ onConfirm, onCancel, message }) => (
  <div style={styles.overlay}>
    <div style={styles.modal}>
      <h2>Are you sure?</h2>
      <p>{message}</p>
      <div style={styles.buttons}>
        <button onClick={onConfirm} style={styles.button}>
          Yes
        </button>
        <button onClick={onCancel} style={styles.button}>
          Cancel
        </button>
      </div>
    </div>
  </div>
);

const styles: Record<string, React.CSSProperties> = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    padding: "30px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 6px 10px rgba(0,0,0,0.2)",
    width: "80%",
    maxWidth: "600px",
    fontSize: "24px",
  },
  buttons: {
    marginTop: "30px",
  },
  button: {
    margin: "20px 30px", // Increased the margin around the buttons
    padding: "20px 40px", // Increased padding to make the buttons larger
    fontSize: "24px", // Increased font size for better visibility
    cursor: "pointer",
    borderRadius: "8px", // Rounded corners for a smoother look
    background: "#007BFF", // Stylish blue background
    color: "white", // High contrast text color for better readability
    border: "none",
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // More prominent shadow for a 3D effect
  },
};

export default MyModal;
