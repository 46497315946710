import { useDispatch, useSelector } from "react-redux";
import NewWorkRopeBead from "../NewWorkRopeBead.tsx";
import {
  createProgressRecord,
  updateProgressRecord,
} from "../../slices/progressSlice.ts";
import { ProgressData, StudentData } from "../../types.ts";
import React from "react";
import { RootState } from "../../store";
import { Work } from "../../slices/worksSlice.ts";

interface WorkRopeBeadWithNoteCellProps {
  singleProgress: ProgressData | undefined;
  work: Work;
  student: StudentData;
}

const WorkRopeBeadWithNoteCell: React.FC<WorkRopeBeadWithNoteCellProps> = ({
  singleProgress,
  work,
  student,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const activeStudentNotesID = useSelector(
    (state: RootState) => state.student.activeStudent
  );
  const subject = useSelector(
    (state: RootState) => state.student.activeSubject
  );

  const status = singleProgress?.status || "Unplanned";
  const withNoteIcon = singleProgress && singleProgress?.notes != "";

  return (
    <>
      <NewWorkRopeBead
        status={status}
        subject={subject}
        withNoteIcon={withNoteIcon}
        progressRefPath={singleProgress?.id}
        workRefPath={work.workRefPath}
        studentRefPath={student.refPath}
        studentName={student.name}
        workName={work.workName}
      />
      {student.refPath === activeStudentNotesID?.refPath && (
        <td
          className="NoteCell"
          contentEditable={true}
          onBlur={(e) => {
            const updatedNotes = e.target.textContent;
            if (singleProgress?.id) {
              dispatch(
                updateProgressRecord({
                  teacherUid: user?.id as string,
                  progressId: singleProgress.id,
                  updates: { notes: updatedNotes || "" },
                  olds: { notes: singleProgress.notes },
                })
              );
            } else {
              dispatch(
                createProgressRecord({
                  teacherUid: user?.id as string,
                  newProgress: {
                    id: "",
                    status: "Unplanned",
                    notes: updatedNotes || "",
                    subject: subject,
                    studentRefPath: student.refPath,
                    workRefPath: work.workRefPath,
                  },
                })
              );
            }
          }}
        >
          {singleProgress?.notes || ""}
        </td>
      )}
    </>
  );
};

export default WorkRopeBeadWithNoteCell;
